/* Import Montserrat font from Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');

/* Tailwind CSS Directives */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom Global Styles */
body {
  @apply font-sans; /* Apply Montserrat font globally */
}

/* Additional custom styles can be added here */